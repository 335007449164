import React from 'react';
import PropTypes from 'prop-types';
import {withStyles } from '@material-ui/core/styles';
import classNames from 'classnames'
import styleSheet from './style.js';

class CustomTypography extends React.Component {
  render() {
    const {
        classes,
      align,
      embed,
      children,
      className: propsClassName,
      color,
      // component,
      fontSize,
      style,
      type,
      variant,
      weight,
      inner,
      ...restProps
    } = this.props;

    let className = classNames(classes.default, {
      [classes[variant]]   : !!variant,
      // [classes.typography] : true,
      // [classes[type]]      : !loIsNil(type),
      [classes[color]]     : !!color,//!loIsNil(color),
      [classes[weight]]    : !!weight,//!loIsNil(weight),
      [classes[align]]     : !!align,
    }, propsClassName);
    const allProps = {
      className,
      style: {
        fontSize,
        ...style,
      },
      ...restProps,
    };

    return inner ? ( <div {...allProps} dangerouslySetInnerHTML={{__html: inner}}></div>)  : ( <div {...allProps}>{children}</div>);

  }
}

CustomTypography.defaultProps = {
  embed     : true,
  className : '',
  variant: '',
  // variant   : 'body1',
  style     : {},
};

CustomTypography.propTypes = {
  // classes   : PropTypes.object.isRequired,
  // embed     : PropTypes.bool.isRequired, //NOTE: this is how hive compoennts decides which component to render,
  className : PropTypes.string.isRequired,
  // See stylesheet for available colour/weight/variant
  align     : PropTypes.string,
  color     : PropTypes.string,
  fontSize  : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  weight    : PropTypes.string,
  variant   : PropTypes.string,
  // type      : PropTypes.oneOf(['filterTitle']),
  style     : PropTypes.object.isRequired,
  children  : PropTypes.node,
};

export default withStyles(styleSheet)(CustomTypography);
