import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames'
import {Paper as MUIPaper} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
  },
}));

const Paper = ({children, className, ...restProps}) => {
    const classes = useStyles()
    return (
        <MUIPaper className={classNames(classes.paper, className)} {...restProps}>
            {children}
        </MUIPaper>
    )
}

export default Paper
