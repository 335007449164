import { combineReducers } from 'redux';
import profileReducer from './profile';
import sheetDataReducer from './sheetData'
import filtersReducer from './filters'

// export default function createReducer(injectedReducers = {}) {
//   const rootReducer = combineReducers({
//       profile            : profileReducer,
//       sheetData: sheetDataReducer
//   });
//
//   return rootReducer;
// }

export default combineReducers({
  profile            : profileReducer,
  sheetData          : sheetDataReducer,
  filters: filtersReducer
});
