import { getActions } from '../utils/actions';
import {apiCall} from '../utils/api'


export const submitFilters = (selectedFilters) => {
  const actions = getActions('SUBMIT_FILTERS');
  return async (dispatch, getState) => {
    return dispatch(actions.succeeded(selectedFilters));
  };
};
