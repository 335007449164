import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector, useDispatch } from 'react-redux'
import loIsArray from 'lodash/isArray'
import loIsNull from 'lodash/isNull'
import loIsEmpty from 'lodash/isEmpty'
import loGet from 'lodash/get'
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  Select,
  MenuItem,
  Popover,
  Box,
  SwipeableDrawer
} from '@material-ui/core';
import Button from '../presentation/Button';
import Paper from '../presentation/Paper';
import Typography from '../presentation/Typography';
import VirtualizedSelect from '../presentation/VirtualizedSelect';
import COLUMN_NAMES, {YES_VALUE, NO_VALUE, EXCLUDED_COLUMNS_FROM_FILTERS} from '../../constants/columnNames'

const useStyles = makeStyles(theme => ({
  filterIcon: {
    cursor: 'pointer'
  },
  largeIcon: {
    fontSize: 46
  }
}))

//my main note off the bat is to take off contact, email, phone, address, notes, EPIP: survey, EPIP: reached out, EPIP: relationship lead, EPIP: outreach date from refined search





const Filters = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const {
    onSubmit,
    onReset,
    columns
  } = props
  const filterableColumns = React.useMemo(() => {
    return columns.filter(columnName => !EXCLUDED_COLUMNS_FROM_FILTERS.includes(columnName))
  }, [columns])
  const submittedFilters = useSelector(state => state.filters.submitted)
  const dropdownOptionsByColumn = useSelector(state => state.filters.dropdownOptions)

  const [selectedFilters, setSelectedFilters] = React.useState({}) //TODO move to store
  const [isOpen, setIsOpen] = React.useState(false)
  const filterButtonEl = React.useRef(null)

  const areFiltersApplied = React.useMemo(() => {
    return !loIsEmpty(submittedFilters)
  }, [submittedFilters])


    const openFilterModal = () => {
      setSelectedFilters(submittedFilters)
      setIsOpen(true)
    }

    const closeFilterModal = () => {
      setIsOpen(false)
    }
    const handleSubmit = () => {
      onSubmit(selectedFilters)
      setIsOpen(false)
    }

    const handleResetFilters = () => {
      onReset()
    }

    const onUpdateColumnFilter = (columnName) => (val) =>  {
      console.log('updating column filter', columnName, 'with val', val)
      if(loIsEmpty(val))  { //if this filter was cleared, delete key entirely
        console.log('value was empty, deleting key')
        return setSelectedFilters(currSelected => {
          const updated = {...currSelected}
          delete updated[columnName]
          return updated
        })
      }
      setSelectedFilters(curr => ({
        ...curr,
        [columnName]: val
      }))

    }

    const renderDropdownField = (columnName) => {
      const options = dropdownOptionsByColumn[columnName]
      return (
        <div key={`${columnName}-filter`} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '6px 0px'}}>
          <Typography fontSize={10} weight="semibold" color="navy">{columnName}</Typography>

          <Box width={'60%'} minWidth="200px" ml="12px">
            <VirtualizedSelect
              onChange={onUpdateColumnFilter(columnName)}
              value={selectedFilters[columnName]}
              options={dropdownOptionsByColumn[columnName]}
            />
          </Box>

        </div>
      )
    }

    const renderCheckboxField = (columnName) => {
      const options = dropdownOptionsByColumn[columnName]
      // const value = selectedFilters[columnName] || []
      //its checked if we have an applied filter with the value yes
      
      const isChecked = loGet(selectedFilters, [columnName, 0, 'value']) === YES_VALUE
      return (
        <div key={`${columnName}-filter`} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '6px 0px'}}>
          <Typography fontSize={10} weight="semibold" color="navy">{columnName}</Typography>
          <Box width={'50%'} minWidth="200px" ml="12px">
          {/* <Checkbox
        checked={isChecked}
        onChange={(e) => {
          console.log('original state', isChecked)
          console.log('new state', e.target.checked)
          const newVal = !e.target.checked ? [] : [{label: YES_VALUE, value: YES_VALUE}]
                // console.log('e changing from is checked',isChecked, e.target)
          onUpdateColumnFilter(columnName)(newVal)
        }}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      /> */}
            <input
              type="checkbox"
              value="yes"
              checked={isChecked}
              onChange={(e) => {
              
                const newVal = !e.target.checked ? [] : [{label: YES_VALUE, value: YES_VALUE}]
                onUpdateColumnFilter(columnName)(newVal)
              }}

            />
          </Box>

        </div>
      )
    }

    return (
        <Box width="100%" display="flex" alignItems="center">
          <Box position="relative" alignItems="center" >
            <FilterListIcon
              classes={{
                fontSizeLarge: classes.largeIcon
              }}
              className={classes.filterIcon}
              onClick={openFilterModal}
              ref={filterButtonEl.current}
              fontSize="large"
              // color={areFiltersApplied ? 'action' : 'disabled'}
              style={{ color: areFiltersApplied ? 'white' : '#f7f7f7' }}
            />
            {areFiltersApplied && (
              <div
                style={{width: 18, height: 18, borderRadius: '50%', color: 'white', backgroundColor: theme.civicPalette.red, position: 'absolute', top: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 10, fontWeight: 'bold'
                       }}>
                  {Object.keys(submittedFilters).length}</div>
                )}
            </Box>

              {areFiltersApplied && <Button
                size="xs"
                variant="text"
                onClick={handleResetFilters}
                style={{color: 'white'}}
                                    >
                Reset Filters
              </Button>}


              <SwipeableDrawer
                anchor={'right'}
                open={isOpen}
                onClose={closeFilterModal}
                onOpen={openFilterModal}
                PaperProps={{
                  style: {width: '80%'}
                }}
              >
                <Box height={`calc(100% - 32px)`} py="16px" px="12px" overflow="auto" >
                  {filterableColumns.map(columnName => {
                    const options = dropdownOptionsByColumn[columnName]
                    // const value = selectedFilters[columnName] || []
                    const isYesNo = options.length <= 2 && options.some(option => option.value === YES_VALUE || option.value === NO_VALUE) //its a checkbox if it has only yes/no values

                    if(isYesNo) return renderCheckboxField(columnName)
                    return renderDropdownField(columnName)
                  })}
                </Box>


                <Box height="46px" width="100%" bgcolor={'#f7f7f7'} bottom="0" display="flex" px="24px" py="4px" alignItems="center" justifyContent="space-between">
                  <Button  variant="text" onClick={closeFilterModal}>
                Cancel
              </Button>
              <Button variant="orangeFill" onClick={handleSubmit}>
                Submit
              </Button>
            </Box>

          </SwipeableDrawer>


        </Box>
    )
}

export default Filters
