import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress'
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import STATIC_URLS from './constants/staticUrls'
import LoginPage from './components/LoginPage'
import NavBar from './components/NavBar'
import Home from './components/HomePage'
import PageLoader from './components/presentation/PageLoader'
import { refreshTokenSetup } from './utils/refreshToken';
import {fetchProfile} from './actions/auth'

const routes = [
  {
    name: 'Home',
    props: {
      path: STATIC_URLS.HOME,
      exact: true,
      component: Home,
      needsAuth: true,
      // component: () => <Redirect to={STATIC_URLS.IMAGES} /> ,
    },
  },
  {
    name: 'Login',
    props: {
      path: STATIC_URLS.LOGIN,
      exact: true,
      component: LoginPage,
    },
  },
  // {
  //   name: 'Not Found',
  //   props: {
  //     path: '*',
  //     component: () => <Redirect to={STATIC_URLS.HOME} />
  //   },
  // },
];

const NAVBAR_HEIGHT = 48
const styleSheet = (theme) => ({
    root: {
        backgroundColor: theme.pageBackground,//aqua,
        height: '100vh',//`calc(100vh - ${NAVBAR_HEIGHT}px)`,
        display: 'flex',
        backgroundImage: "url(./organic_shapes.svg)",
        backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center"
    },
    content: {
        flex: 1,
        marginTop: NAVBAR_HEIGHT,
        width: '100%'
    },
    paddedContent: {
        padding: 24,

    }

})

  const useStyles = makeStyles(styleSheet);

const App = (props) => {
    const classes = useStyles()
const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const isFetchingProfile = useSelector(state => state.profile.loading)
  const profileData = useSelector(state => state.profile.data)


  React.useEffect(() => {
      initUser()
  }, [])

  const initUser = async () => {
    const res = await dispatch(fetchProfile())
    if(!res.error) {
        history.push(STATIC_URLS.HOME)
    } else {
      console.log('session did not exist, user must login')
    }
  }

    if (isFetchingProfile) return  <PageLoader height="100vh"/>
    return (
      <div className={classes.root}>
        <NavBar />
        <div className={classNames(classes.content, {[classes.paddedContent]: true})}>
          <Switch>
            {
              routes.map((route) => {
                const {
                  name,
                  props: routeProps,

                } = route;
                const { component, needsAuth, staticComponent, ...restRouteProps } = routeProps;
                const FinalComponent = component;

                return (
                  <Route
                    key={name}
                    {...restRouteProps}
                    render={(props) => {
                      if (needsAuth && !profileData) { //if its protected route and were not logged in
                                          return <Redirect from={restRouteProps.path} to={STATIC_URLS.LOGIN} />;
                                      }
                                      return <FinalComponent
                                          {...props}
                                          // onSuccessfulLogin={onSuccessfulLogin}
                                          // data={data}
                                          // {...data}
                                             />;
                                  }}
                              />);
                      })
                  }
                  <Redirect from="*" to={STATIC_URLS.HOME} />
              </Switch>
          </div>
      </div>
    );
}

export default App;
