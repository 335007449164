import {createSelector} from 'reselect'
import loGet from 'lodash/get'
import loIsEmpty from 'lodash/isEmpty'
import {parseCellIntoValueArray} from '../constants/filters'


const getSheetData = (state, props) => {
  return loGet(state, 'sheetData.data')
}

const getSubmittedFilters = (state, props) => {
  return loGet(state, 'filters.submitted', {})
}


export const getFilteredSheetData = createSelector(
  [
    getSheetData,
    getSubmittedFilters
  ],
  (sheetData, submittedFilters) => {
    // console.log('recomputing filtered ddata from sheetdata', sheetData, 'submitted filters', submittedFilters)

    const {
      columns,
      mappedRows,
      rows
    } = sheetData

    let filteredRows = []
    let filteredMappedRows = []


    const columnsToFilterBy = Object.keys(submittedFilters) //keys will be the column name that is being filterd

    if(loIsEmpty(columnsToFilterBy) || loIsEmpty(rows)) {
      return sheetData
    }


    mappedRows.forEach((mappedRow, rowIndex) => {

      let includeRow = true


      columnsToFilterBy.forEach(columnName => {
        const appliedFiltersForColumn = submittedFilters[columnName].map(obj => obj.value)
        //TODO:
        //1. handle special cases where the filtered value may not match exactly
        //2. Y/N columns, MATCH WITH yes/Y/ and others


        const rawCellValue = mappedRow[columnName]
        const cellValArray = parseCellIntoValueArray(rawCellValue)

        //if the cell contains atleast one of the values that were filtering on for this column, include the row
        const doesCellContainFilteredValue = cellValArray.some(val => appliedFiltersForColumn.includes(val))
        if(!doesCellContainFilteredValue) includeRow = false
      })

      if(includeRow) {
        filteredRows.push(rows[rowIndex])
        filteredMappedRows.push(mappedRow)
      }
    })

    return {
      columns,
      mappedRows: filteredMappedRows,
      rows: filteredRows
    }

  }
)
