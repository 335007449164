export default theme => ({
  // default: {
  //   fontFamily: 'inherit'
  // },
    center: {
        textAlign: 'center'
    },
    left: {
        textAlign: 'left'
    },
    right: {
      textAlign: 'right'
    },
    light: {
        fontWeight: 300
    },
    regular: {
        fontWeight: 400
    },
    semibold: {
        fontWeight: 600
    },
    bold: {
        fontWeight: 700
    },
    white: {
        color: 'white'
    },
    red: {
        color: theme.civicPalette.red
    },
    orange: {
        color: theme.civicPalette.orange
    },
    yellow: {
        color: theme.civicPalette.yellow
    },
    paleYellow: {
        color: theme.civicPalette.paleYellow
    },
    mint: {
        color: theme.civicPalette.mint
    },
    navy: {
        color: theme.civicPalette.navy
    },
    aqua: {
        color: theme.civicPalette.aqua
    },
    black: {
        color: theme.civicPalette.black
    },
    grey: {
        color: 'grey'
    }
})
