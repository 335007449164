import React from 'react'
import { fade, makeStyles, } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import {
  Dialog,
  Input,
  Box,
  Card,
  CardContent,
  ListItem,
  ListItemText
} from '@material-ui/core';
import loDebounce from 'lodash/debounce'
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import {fetchSheetData} from '../../actions/sheetData'
import VendorDetailPage from '../VendorDetailPage'
import COLUMN_NAMES from '../../constants/columnNames'
import Filters from './Filters'
import FilterShortcuts from './FilterShortcuts'
import {getFilteredSheetData} from '../../selectors/sheetData'
import Typography from '../presentation/Typography'
import SearchInput from '../presentation/SearchInput'
import {submitFilters} from '../../actions/filters'
import VendorRow from './VendorRow'
const CARD_HEIGHT = 115
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% + 21px)'
  },
  searchInput: {
    // width: '50%',
    // maxWidth: 400
  },
  listContainer: {
      overflow: 'auto',
      flex: 1
  },
  card: {
    cursor: 'pointer',
    height: CARD_HEIGHT
  },
  cardContent: {
    padding: 12,
    '&:last-child': {
      padding: 12
    }
  }
}));




const SEARCHABLE_COLUMNS = [
    COLUMN_NAMES.CONTACT,
    COLUMN_NAMES.COMPANY,
    COLUMN_NAMES.EMAIL,
    COLUMN_NAMES.LOCATION,
    COLUMN_NAMES.CATEGORY,
    COLUMN_NAMES.SERVICES,
    COLUMN_NAMES.NOTES,
    COLUMN_NAMES.PRIMARY_ADDRESS
]

const getFilteredRows = ({rows, searchText, mappedRows, filters}) => {
  let rowIndicesToShow = []
  let rowsToShow = []
  const shouldIncludeRow = (row, rowIndex) => {
      return SEARCHABLE_COLUMNS.some(columnName => {
          const rowValForColumn = mappedRows[rowIndex][columnName]
          if(!rowValForColumn || !rowValForColumn.length) return false

          const isMatch = rowValForColumn.toLowerCase().includes(searchText.toLowerCase())
          return isMatch
      })
    // return row.some(cellVal => {
    //     if(!cellVal) return false
    //     return (cellVal || '').toLowerCase().includes(searchText.toLowerCase())
    // })
  };
  rows.forEach((row, rowIndex) => {
      const includedInFilter = shouldIncludeRow(row, rowIndex)
      if(includedInFilter) {
        rowIndicesToShow.push(rowIndex)
        rowsToShow.push(row)
      }
  })
  return [rowsToShow, rowIndicesToShow]//rowIndicesToShow
}


const VendorList  = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    rows,
    mappedRows,
    columns,
  } = useSelector(getFilteredSheetData)
  const submittedFilters = useSelector(state => state.filters.submitted)
  const [searchText, setSearchText] = React.useState("")

  const [detailDialogRowIndex, setDetailDialogRowIndex] = React.useState(-1) //non negative integer will represent the opened row

  const [visibleRows, visibleMappedRows] = React.useMemo(() => {

      if(!searchText || !searchText.length) return [rows, mappedRows]

      const [rowsToShow, visibleIndices] = getFilteredRows({
        rows,
        searchText,
        mappedRows,
        filters: submittedFilters
      })
      const visibleMappedRows  = visibleIndices.map(rowIndex => mappedRows[rowIndex])
      return [rowsToShow, visibleMappedRows]

  }, [rows, searchText, mappedRows, submittedFilters])

  const openDetailPage = (rowIndex) => setDetailDialogRowIndex(rowIndex)
  const onSearchChange = (e) => {
      setSearchText(e.target.value || "")
  }

  const onSubmitFilters = (selections) => {
    dispatch(submitFilters(selections))
  }

  const onResetFilters = () => {
    dispatch(submitFilters({}))
  }

  const isDetailPageOpen = detailDialogRowIndex != -1

  return (
    <div className={classes.root}>
      <Box display="flex" mb='6px'>
        <SearchInput
          className={classes.searchInput}
          value={searchText}
          onChange={onSearchChange}
        />
        <Box width="50%">
          <Filters
            columns={columns}
            onSubmit={onSubmitFilters}
            onReset={onResetFilters}

          />
        </Box>
      </Box>
      <FilterShortcuts columns={columns}/>

      <Typography color="white" fontSize={14} weight="semibold" align="right">Results: {visibleRows.length} </Typography>

      <Box height="100%" style={{marginTop: 0}} pt={1}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="List"
              height={height}
              itemCount={visibleRows.length}
              itemSize={CARD_HEIGHT+6}
              width={width}
              itemData={{
                  // rows,
                  searchText,
                  cardHeight: CARD_HEIGHT,
                  visibleRows,
                  mappedRows: visibleMappedRows,
                  openDetailPage

              }}
            >
              {VendorRow}
            </List>
          )}

        </AutoSizer>
        {visibleRows.length === 0 && <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
                                     >
          <Typography
            color="white"
            fontSize={24}
            weight="semibold"
          >
            No results found for selected filters.
          </Typography>
        </Box>}
      </Box>



      {isDetailPageOpen &&  <VendorDetailPage
        onClose={() => setDetailDialogRowIndex(-1)}
        mappedRow={visibleMappedRows[detailDialogRowIndex]} //Use visible (filtered rows) when doing index lookup
                              />
        }
    </div>
  )
}

export default VendorList
