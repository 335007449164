


const COLUMN_NAMES = {
    VENDOR_TYPE: "VENDOR TYPE",
    REGION: "REGION",
    LOCATION: "LOCATION",
    CATEGORY: "CATEGORY",
    SERVICES: "SERVICES",
    "COMPANY": "COMPANY",
    "WEBSITE": "WEBSITE",
    "CONTACT": "CONTACT",
    // "FIRST": "FIRST",
    // "LAST": "LAST",
    "EMAIL": "EMAIL",
    PHONE: "PHONE",
    PRIMARY_ADDRESS: "PRIMARY ADDRESS",
    // "STREET ADDRESS": "STREET ADDRESS",
    // "CITY": "CITY",
    // "STATE": "STATE",
    // "ZIP": "ZIP",
    CIVIC_REFERENCE: "CIVIC REFERENCE",
    PAST_PROJECTS: "PAST PROJECTS",
    NOTES: "NOTES",
    EPIP_SURVEY: "EPIP SURVEY",
    EPIP_REACHED_OUT: "EPIP: REACHED OUT",
    EPIP_RELATIONSHIP_LEAD: "EPIP: RELATIONSHIP LEAD",
    EPIP_OUTREACH_DATE: "EPIP: OUTREACH DATE",
    NUM_EMPLOYEES: "# full time employees",
    SMALL_BUSINESS: "Small Business",
    SMALL_DISADVANTAGED_BUSINESS: "Small Disadvantaged Business",
    HUB: "HUB",
    OTHER: "Other",
    WBENC: "WBENC",
    NMSDC: "NMSDC",
    DISABILITY_IN: "Disability: IN",
    PERCENT_SENIOR_MGMT: "% Senior Management",
    PERCENT_SENIOR_MGMT_FEMALE: "% Senior Management Female-Identifying",
    PERCENT_EMPLOYEE_FEMALE: "% Employee Base Female-identifying",
    PERCENT_EMPLOYEE: "% Employee Base",
    NGLCC: "NGLCC",
    FEDERAL_GOVERNMENT: "Federal government",
    STATE_GOVERNMENT: "State government",
    LOCAL_GOVERNMENT: "Local government",
    AT_LEAST: "at least 51% owned, controlled, managed by"
}


export const YES_VALUE = 'yes' //Must match cell value exactly
export const NO_VALUE = 'no'


export const EXCLUDED_COLUMNS_FROM_FILTERS = [
  COLUMN_NAMES.CONTACT,
  COLUMN_NAMES.COMPANY,
  COLUMN_NAMES.WEBSITE,
  COLUMN_NAMES.PAST_PROJECTS,
  COLUMN_NAMES.EMAIL,
  COLUMN_NAMES.PRIMARY_ADDRESS,
  COLUMN_NAMES.PHONE,
  COLUMN_NAMES.EPIP_SURVEY,
  COLUMN_NAMES.EPIP_REACHED_OUT,
  COLUMN_NAMES.EPIP_RELATIONSHIP_LEAD,
  COLUMN_NAMES.EPIP_OUTREACH_DATE,
  COLUMN_NAMES.NOTES,
  COLUMN_NAMES.CIVIC_REFERENCE,
  COLUMN_NAMES.EPIP_SURVEY,
  COLUMN_NAMES.EPIP_REACHED_OUT,
  COLUMN_NAMES.EPIP_RELATIONSHIP_LEAD,
  COLUMN_NAMES.NUM_EMPLOYEES,
  COLUMN_NAMES.SMALL_BUSINESS,
  COLUMN_NAMES.SMALL_DISADVANTAGED_BUSINESS,
  COLUMN_NAMES.HUB,
  COLUMN_NAMES.OTHER,
  COLUMN_NAMES.WBENC,
  COLUMN_NAMES.NMSDC,
  COLUMN_NAMES.DISABILITY_IN,
  COLUMN_NAMES.PERCENT_EMPLOYEE,
  COLUMN_NAMES.PERCENT_EMPLOYEE_FEMALE,
  COLUMN_NAMES.PERCENT_SENIOR_MGMT,
  COLUMN_NAMES.PERCENT_SENIOR_MGMT_FEMALE,
  COLUMN_NAMES.NGLCC,
  COLUMN_NAMES.FEDERAL_GOVERNMENT,
  COLUMN_NAMES.STATE_GOVERNMENT,
  COLUMN_NAMES.LOCAL_GOVERNMENT,
  COLUMN_NAMES.AT_LEAST
];


export default COLUMN_NAMES
