import {parseCellIntoValueArray} from '../constants/filters'

const initialState = {
  dropdownOptions: {

  },
  selected: {
    //TODO
  },
  submitted: {

  }

}

const generateDropdownOptionsForColumns = (payload) => {
  const {columns, mappedRows} = payload
  if(!columns || !columns.length || !mappedRows || !mappedRows.length) return {}

  const dropdownOptionsByColumn = {}
  columns.forEach(columnName => {
    const columnValueSet = new Set()
    mappedRows.forEach((row, i) => {

      const columnValArray = parseCellIntoValueArray(row[columnName])
      columnValArray.forEach(item => columnValueSet.add(item))
    })
    const options = Array.from(columnValueSet)
    dropdownOptionsByColumn[columnName] = options.map(optionVal => ({label: optionVal, value: optionVal}))
  })
  return dropdownOptionsByColumn
}

const sheetDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SUBMIT_FILTERS': {
      return {
        ...state,
        submitted: action.payload
      }
    }
    case 'FETCH_SHEET_DATA': {
      if(action.loading) {
          return {
              ...state,
              loading: true,
              error: false
          }
      } else if(action.error) {
          return {
              ...state,
              loading: false,
              error: true
          }
      }
      return {
          ...state,
          dropdownOptions: generateDropdownOptionsForColumns(action.payload)
      }
    }
    default:
        return state
  }

};

export default sheetDataReducer;
