export default theme => ({
    default: {
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
        textAlign: 'center',
        borderRadius: 6,
        cursor: 'pointer',
        fontSize: 16,
        padding: '6px 16px',
        color: 'grey',
        fontWeight: 600
    },
    link: {
        position: 'relative',
        textDecoration: 'none'
    },
    aquaFill: {
      backgroundColor: theme.civicPalette.aqua,
      color: 'white'
    },
    orangeFill: {
        backgroundColor: theme.civicPalette.orange,
        color: 'white'
    },
    text: {
      color: '#c0c0c0'
    },
    xs: {
      fontSize: 12,
      height: 22,
      padding: '4px 10px'
    }
})
