// const apiUrl = process.env.API_URL; //NOTE: we dont need the port since we store the localhost url
// const urlPrefix = process.env.NODE_ENV === "STAGING" ? "https://civic-vendors-backend-app.herokuapp.com" : 'http://localhost:3003';

const LOCAL_API = 'http://localhost:3003'
const STAGING_API = "https://civic-vendor-backend.herokuapp.com"
const PRODUCTION_API = STAGING_API
// const urlPrefix =  process.env.HEROKU_ENV === "STAGING" ? STAGING_API : LOCAL_API
console.log('process.env.REACT_APP_STAGE',process.env.REACT_APP_STAGE)
let urlPrefix;
switch(process.env.REACT_APP_STAGE) {
  default:
  case 'staging':
    urlPrefix = STAGING_API
    break;
  case 'production':
    urlPrefix = PRODUCTION_API
    break;
  case 'local':
    urlPrefix = LOCAL_API
    break;
}
//(apiUrl || `${window.location.origin}`);//isLocal && !apiUrl ? `http://localhost:${apiPort}` : (apiUrl ||

const isStatusCodeNonSuccess = n => Math.floor(n / 100) !== 2;

export const apiCall = (requestMethod, { url, body}) => {
      console.log('url prefix:' , urlPrefix);
      console.log('env: ', process.env.HEROKU_ENV);
      return fetch(`${urlPrefix}${url}`, {
        method      : requestMethod,
        credentials : 'include',
        headers     : new Headers({
          'Access-Control-Allow-Credentials' : 'true',
          'Content-Type'                     : 'application/json',
          'X-Forwarded-Proto'                : 'https'
        }),
        body: body ? JSON.stringify(body) : undefined,
      })
      .then(fetchResponse => {
        if (!fetchResponse || (fetchResponse.status && isStatusCodeNonSuccess(fetchResponse.status))) {
          throw fetchResponse;
        }
        return fetchResponse.json();
      })
      .then(jsonResponse => {
        return jsonResponse;
      });
    };
