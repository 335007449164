import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {Box, CircularProgress } from '@material-ui/core';
import Typography from '../Typography';


const styleSheet = (theme) => ({
  spinner: {
    color: 'white'
  },
  text: {
    color: 'white',
    marginTop: 24
  },
  errorIcon: {

  }
})
const useStyles = makeStyles(styleSheet)

const PageLoader = (props) => {

  const classes = useStyles()
  const theme = useTheme()

  const {
    showError, //controls error state
    text,
    size = 60,
    width = "100%",
    height = "100%"
  } = props
  return (
    <Box
      
      width={width}
      height={height}
      display="flex"
      flexDirection="column"
      alignItems="center"
    justifyContent="center">
      {showError ? <ErrorOutlineIcon style={{ fontSize: 60, color: 'white' }} className={classes.errorIcon}/> : <CircularProgress className={classes.spinner} size={size} />}
      {text &&   (
        <Typography
          weight="light"
          color="grey"
          align="center"
          className={classes.text}
        >
          {text}
        </Typography>
      )}
    </Box>
  )
}
export default PageLoader
