import {
  createMuiTheme,
} from '@material-ui/core/styles';

const customTheme = createMuiTheme({
    civicPalette: {
        red: '#ff2e06',
        orange: '#ff7321',
        yellow: '#eec500',
        paleYellow: '#f3fad6',
        mint: '#e7fbe0',
        navy: '#0b434f',
        aqua: '#99d6c9',
        black: '#1c2222'
    },
    pageBackground: '#99d6c9',
    navbarBackground: 'white'
})

export default customTheme
