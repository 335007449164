const initialState = {
    loading: true,
    error: false,
    data: null
}

const sheetDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SHEET_DATA': {
      if(action.loading) {
          return {
              ...state,
              loading: true,
              error: false
          }
      } else if(action.error) {
          return {
              ...state,
              loading: false,
              error: true
          }
      }
      return {
          ...state,
          loading: false,
          error: false,
          data: action.payload
      }
    }
    default:
        return state
  }

};

export default sheetDataReducer;
