import { getActions } from '../utils/actions';
import {apiCall} from '../utils/api'
// import STATIC_URLS from '../constants/staticUrls'


export const fetchProfile = () => {
  const actions = getActions('FETCH_PROFILE');
  return async (dispatch, getState) => {
    dispatch(actions.requested());

    try {
        const res = await apiCall('GET', {
          url: '/auth/fetchProfile',
        })
        return dispatch(actions.succeeded(res));
    } catch(e) {
        return dispatch(actions.failed({ error: e }));
    }


  };
};

export const login = (googleData) => {
  const actions = getActions('LOGIN');

  return async (dispatch, getState) => {

    dispatch(actions.requested());

    try {
        const res = await apiCall('POST', {
          url: '/auth/google',
          body: googleData
        })
        return dispatch(actions.succeeded(res));
    } catch(e) {
        return dispatch(actions.failed({ error: e }));
    }

    // const {
    //   res,
    //   error,
    // } = await executeRequest.POST({
    //   url: '',
    //   body: payload,
    // });
    //
    // if (error) return dispatch(actions.failed({ error }));
    //
    //
    // return dispatch(actions.succeeded(res));
  };
};
//
// export const logout = (payload) => {
//   const actions = getHttpActions(LOGOUT, { requireAuth: false });
//
//   return async (dispatch, getState) => {
//
//     dispatch(actions.requested());
//     const url = `${USER_BASE_URL}/logout`;
//
//     const {
//       res,
//       error,
//     } = await executeRequest.DELETE({
//       url,
//     });
//
//     if (error) return dispatch(actions.failed({ error }));
//
//     return dispatch(actions.succeeded(res));
//   };
// };

export const logout = (googleData) => {
  const actions = getActions('LOGOUT');

  return async (dispatch, getState) => {

    dispatch(actions.requested());

    try {
        const res = await apiCall('GET', {
          url: '/auth/logout',
        })
        return dispatch(actions.succeeded(res));
    } catch(e) {
        return dispatch(actions.failed({ error: e }));
    }
  };
};
