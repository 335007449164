const parseRawValue = (strVal = '') => {
  return strVal.split(/\s*[,\n]+\s*/) //split on newlines and commas
}

export const parseCellIntoValueArray = (cellVal = '') => {
  //make each cell into array, split on newlines, and comma
  const columnValArray = parseRawValue(cellVal)

  return columnValArray.reduce((cleanedValues, currVal) => {
    const trimmedVal = currVal.trim()
    if(!!trimmedVal) { //only include non empty or undefined values in the array
      cleanedValues.push(trimmedVal)
    }
    return cleanedValues
  }, [])
}
