import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {AppBar, Toolbar, Box} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../presentation/Typography'
import Button from '../presentation/Button'
import {logout} from '../../actions/auth'
import civicLogo from '../../assets/civic-logo-grey.png'

const styleSheet = (theme) => ({
    root: {
        backgroundColor: theme.navbarBackground,//'white',
        // height: '100vh',
        // display: 'flex'
        height: 48
    },
    toolbar: {
        height: 48,
        minHeight: 48,
        justifyContent: 'space-between'
    },
    email: {
      marginRight: 6
    },

})

  const useStyles = makeStyles(styleSheet);

const NavBar = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const profileData = useSelector(state => state.profile.data)
    const handleLogout = () => {
      console.log('handle logout clicked');
        dispatch(logout())
    }

    const isAuthenticated = profileData != null
    return (
        <AppBar position="fixed" className={classes.root}>
          <Toolbar className={classes.toolbar}>
            {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton> */}
            <Box display="flex" alignItems="center">
              <img src={civicLogo} style={{height: 42, marginRight: 6}} />
              <Typography
                className={classes.title}
              weight="regular" color="grey" align="center">Vendor Database</Typography>
            </Box>

            <Box display="flex" alignItems="center">
              {isAuthenticated ? (
                <>
                  <AccountCircle color="disabled"/>
                <Typography color="grey" fontSize={12} className={classes.email}>
                  {profileData.email}
                </Typography>
                <Button onClick={handleLogout} size="sm">Logout</Button>
                        </>
                    )
                    :
                    <Button color="inherit">Login</Button>
                    }
                </Box>

        </Toolbar>
        </AppBar>
    )
}

export default NavBar
