import React from 'react'
import { fade, makeStyles, } from '@material-ui/core/styles';
import classNames from 'classnames'
import {
  Dialog,
  Input,
  Box,
  Card,
  CardContent,
  ListItem,
  ListItemText
} from '@material-ui/core';
import Highlighter from 'react-highlight-words';
import COLUMN_NAMES from '../../constants/columnNames'
import Typography from '../presentation/Typography'


const useStyles = makeStyles(theme => ({

  card: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#d9e1e1',//'#e7fbe0'
    }
    // height: 94
  },
  cardContent: {
    padding: '6px 14px',
    '&:last-child': {
      padding: '8px 14px',
    }
  },
  highlightWrapper: {

  },
  highlightText: {
    color: theme.civicPalette.orange,
    fontWeight: 600,
    backgroundColor: 'inherit',
  },
  columnLabel: {
    // fontWeight: 600,
    // fontFamily: "'Pathway Gothic One', sans-serif",
    textTransform: 'capitalize',
    width: 56,
    flex: '0 0 auto',
    fontWeight: 600
  },
  pathwayFont: {
    fontFamily: "'Pathway Gothic One', sans-serif",
    '& *': {
      fontFamily: "'Pathway Gothic One', sans-serif",

    }
  },
  montFont: {
    fontFamily: "Montserrat"
  }
}));

function Row(props) {
  const classes = useStyles()
  const {
    index,
    style,
    data: {
      searchText,
      cardHeight,
      visibleRows,
      mappedRows,
      openDetailPage
    }
  } = props;
  // const rows[index]
  const rowMap = mappedRows[index]
  const rowIndex = index


  const renderHighlightTextFromSearchString = (textString) => {

    if (!textString) return textString; //if we dont have a string dont render highlighter
    const searchWords = searchText.split(' ');
    return (
      <Highlighter
        className={classes.highlightWrapper}
        highlightClassName={classes.highlightText}
        searchWords={searchWords}
        autoEscape={true}
        textToHighlight={textString}
      />
    );
  }

  return (
    <div  style={style} key={index}>
      <Card
        className={classes.card}
        style={{height: cardHeight}}
        variant="outlined"
        onClick={() => openDetailPage(rowIndex)}
      >
        <CardContent className={classes.cardContent}>
          {/* <div onClick={() => setDetailDialogRowIndex(rowIndex)} > */}

          {/* <div style={{display: "flex"}}>
            <Typography className={classes.columnLabel} fontWeight="bold">{(COLUMN_NAMES.COMPANY).toLowerCase()}:</Typography>
            <Typography>{renderHighlightTextFromSearchString(rowMap[COLUMN_NAMES.COMPANY])}</Typography>
          </div> */}
          {
            [
              COLUMN_NAMES.COMPANY,
              COLUMN_NAMES.EMAIL,
              COLUMN_NAMES.LOCATION,
              COLUMN_NAMES.CATEGORY,
              COLUMN_NAMES.SERVICES,
              // COLUMN_NAMES.CONTACT,
            ].map((columnName, i) => {
              const val = rowMap[columnName]
              const isTitle = i === 0

              if(!val) return
              return (
                <div style={{display: "flex", marginBottom: isTitle ? 5:2}}>
                  {i > 0 && <Typography className={classNames(classes.columnLabel, classes.pathwayFont)} fontSize={12} >
                    {columnName.toLowerCase()}:
                  </Typography>}
                  <Typography
                    className={classNames({
                      [classes.montFont]: !isTitle,
                      [classes.pathwayFont]: isTitle
                    })}
                    weight={isTitle ? 'bold' : 'regular' }
                    // fontFamily={!isTitle ?  "Montserrat" : 'Pathway Gothic One'} //Only do montserrat font for the non titles
                    fontSize={isTitle ? 14 : 11}
                  >{renderHighlightTextFromSearchString(rowMap[columnName])}</Typography>
                </div>
              )
            })
          }
        </CardContent>
      </Card>
    </div>

  )
}

export default Row
