import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames'
import styleSheet from './style.js';



const VARIANTS = {
  AQUA_FILL_BUTTON   : 'aquaFill',
  TEXT_BUTTON : 'text',
  ORANGE_FILL_BUTTON: 'orangeFill'
};

class Button extends React.Component {

  handleKeyDown = (e) => {
    if (
      e.key === 'Enter'
    ) this.requestSubmit(e);
  }

  handleClick = (e) => {
    // const buttonNode = this.button.current;
    // if (buttonNode) buttonNode.blur();
    this.requestSubmit(e);
  }

  requestSubmit = (e) => {
    const {
      onClick,
      disabled,
    } = this.props;

    if (disabled) return;
    onClick(e);
    // e.stopPropagation();
  }

  render() {
    const {
        classes,
      children,
      variant,
      className,
      disabled,
      style,
      size,
      // isTabable,
      onClick, // eslint-disable-line
      // ...restProps
    } = this.props;
    const buttonClassName = classNames(
      classes.default,
      {
        [classes[variant]] : !!variant,
        [classes.disabled] : !!disabled,
        [classes[size]]    : !!size,
      },
      className,
    );



    const buttonContent = (
      <div
        className={buttonClassName}
        onClick={this.handleClick}
        tabIndex={0}
        style={style}
      >
        {children}
      </div>
    )

    return buttonContent
    // const isLink = !!to;
    //
    // if (!isLink) return buttonContent;
    //
    // return (
    //   <Link
    //     className={'button-link'}
    //     to={to}
    //   >
    //     {buttonContent}
    //   </Link>
    // );
  }
}

Button.defaultProps = {
  className : '',
  variant   : VARIANTS.ORANGE_FILL_BUTTON,
  disabled  : false,
  onClick   : () => {console.log('oops u didnt give me an on click')},
};

Button.propTypes = {
  children  : PropTypes.node,
  className : PropTypes.string.isRequired,
  disabled  : PropTypes.bool.isRequired,
  onClick   : PropTypes.func.isRequired,
  to: PropTypes.string, //clicking on button should take you to this link
  size      : PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  variant   : PropTypes.oneOf(Object.values(VARIANTS)).isRequired,
};

export default withStyles(styleSheet)(Button);
