import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import { useSelector, useDispatch } from 'react-redux'
import {fetchSheetData} from '../../actions/sheetData'
import VendorDetailPage from '../VendorDetailPage'
import VendorList from './VendorList'
import COLUMN_NAMES from '../../constants/columnNames'
import PageLoader from '../presentation/PageLoader'



const useStyles = makeStyles(theme => ({
}));

const COLUMN_FORMATTERS = {

}

const Home  = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {loading, error, data } = useSelector(state => state.sheetData)

  const fetchData = async () => {
    await dispatch(fetchSheetData())
  }

    React.useEffect(() => {
      fetchData()
    }, [])



  if(loading)  return <PageLoader text={"Loading Data..."}/>
  else if(error) return <PageLoader showError={true} text={"Error Fetching Data. Please try again later."}/>

  if(data != null) return <VendorList/>
  else return <PageLoader showError={true} text={"No data available"}/>

}

export default Home
