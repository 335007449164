import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Dialog, Paper, Grid, Typography, AppBar, Toolbar} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
// import Typography from '@material-ui/core/Typography';
// import CloseIcon from '@material-ui/icons/Close';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux'
import Linkify from 'react-linkify';
import COLUMN_NAMES from '../../constants/columnNames'

const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
      backgroundColor: theme.civicPalette.aqua
    },
    detailsContainer: {
        padding: theme.spacing(5),
        backgroundColor: '#f7f7f7',
        flex: 1
    },
    paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const COLUMN_FORMATTERS = {

}

// First Last
// EMAIL
// CITY STATE
// SERVICES
// > mORE DETAILS
const VendorDetailPage  = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const {
      onClose,
      mappedRow,
  } = props
  const {
      [COLUMN_NAMES.COMPANY]: company,
      [COLUMN_NAMES.EMAIL]: email,
      [COLUMN_NAMES.PHONE]: phone,
      [COLUMN_NAMES.LOCATION]: location,
      [COLUMN_NAMES.SERVICES]: services,
      [COLUMN_NAMES.CATEGORY]: category,
      [COLUMN_NAMES.NOTES]: notes,
      ...restRow
  } = mappedRow
  return (
      <Dialog
        fullScreen
        open={true}
        onClose={onClose}
        // TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Vendor Details
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
          <Grid container spacing={0} className={classes.detailsContainer}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>

                <Typography variant="h6">
                  <b>Company:</b> <Linkify>{company}</Linkify>
                </Typography>
                <Typography variant="body1">
                  <b>Email:</b> <Linkify>{email}</Linkify>
                </Typography>
                <Typography variant="body1">
                  <b>Phone:</b> <Linkify>{phone}</Linkify>
                </Typography>
                <Typography variant="body1">
                  <b>Location:</b> <Linkify>{location}</Linkify>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  <b>Services:</b> <Linkify>{services}</Linkify>
                </Typography>
                <Typography variant="body1">
                  <b>Category:</b> <Linkify>{category}</Linkify>
                </Typography>
              </Paper>
            </Grid>
            {/* <Grid item xs={6}>
              <Paper className={classes.paper}>xs=6</Paper>
            </Grid> */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
              <Typography variant="body1">
                <b>Notes:</b> <Linkify>{notes}</Linkify>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {
                  Object.keys(restRow).map((columnName, columnIndex) => {
                    // const isLink = columnName === COLUMN_NAMES.WEBSITE
                    // const rowValueForColumn = isLink ? (
                    //   <a href={mappedRow[columnName]} target="_blank" rel="noreferrer">{mappedRow[columnName]}</a>
                    // ) : mappedRow[columnName]//row[columnIndex]
                    return (
                      <Typography variant="body2" key={`detail-page-${columnIndex}`}>
                        <b>{columnName}:</b> <Linkify>{mappedRow[columnName]}</Linkify>
                      </Typography>
                    )
                  })
                }
              </Paper>
            </Grid>
              </Grid>


      </Dialog>
  )
}

export default VendorDetailPage
