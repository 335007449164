import React from 'react';
import ReactDOM from 'react-dom';
import {
  // Router,
  BrowserRouter as Router
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import App from './App';
import muiTheme from './muiTheme';
import store from './store'


const history = createBrowserHistory();



ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <MuiThemeProvider theme={muiTheme}>
              <Router>
                  <App />
              </Router>
          </MuiThemeProvider>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
