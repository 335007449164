import React from 'react';
import { useGoogleLogin, GoogleLogin } from 'react-google-login';
import { useSelector, useDispatch } from 'react-redux'
import {Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom'
// import Button from '@material-ui/core/Button';
import Typography from '../../components/presentation/Typography';
import Paper from '../../components/presentation/Paper';
import {login} from '../../actions/auth'
import STATIC_URLS from '../../constants/staticUrls'
import civicLogo from '../../assets/civic-logo-grey.png'
import civicLogoWhite from '../../assets/civic-logo-white.png'
import {ReactComponent as OrganicShapes} from '../../assets/organic_shapes.svg'
// refresh token

const clientId = "14461382008-v1fm0mhbgf614i5hlfd1jeqe5hqms0ul.apps.googleusercontent.com"

const styleSheet = (theme) => ({
    root: {
        // width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    logo: {
      width: 300,
      height: 300,
      [theme.breakpoints.down('xs')]: {
        width: 150,
        height: 150,
        marginBottom: 24
      },
    },
    paper: {
        display: 'flex',
        height: '100%',
        // justifyContent: 'center',
        alignItems: 'center',
        // width: '90%',
        // height: '60%',
        flex: 1,
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        },
        zIndex: 1

    },
    welcomeText: {
      fontSize: 30,
      marginBottom: 24,
      [theme.breakpoints.down('xs')]: {
        fontSize: 18
      },
    }
})

  const useStyles = makeStyles(styleSheet);

const Login = ({onSuccessfulLogin}) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()


  const onFailure = (res) => {
    console.log('Login failed: res:', res);
  };

  const handleLogin = async googleData => {
    const res = await dispatch(login({
      token: googleData.tokenId,
      googleId: googleData.googleId,
      name: googleData.name,
      image: googleData.imageUrl,
      email: googleData.email,
  }))

  if(!res.error) {
      history.push(STATIC_URLS.HOME)
  }
  }


  return (
      <div className={classes.root}>
        <Box width="80%" height="60%">
          {/* <Paper className={classes.paper}>
            <img src={civicLogo} alt="Civic" className={classes.logo}/>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100px">
              <Typography
            className={classes.welcomeText}
              weight="light" color="grey" align="center">Welcome to the Civic Vendors Database!</Typography>

              <GoogleLogin
            clientId={clientId}
            buttonText="Log in with Google"
            onSuccess={handleLogin}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
              />
            </Box>

          </Paper> */}
          {/* <OrganicShapes style={{
            position: "absolute"
          }}/> */}
          <div className={classes.paper}>
            <img src={civicLogoWhite} alt="Civic" className={classes.logo}/>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100px">
              <Typography
                className={classes.welcomeText}
              weight="light" color="white" align="center">Welcome to the Civic Vendor Database!</Typography>

              <GoogleLogin
                clientId={clientId}
                buttonText="Log in with Google"
                onSuccess={handleLogin}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
              />
            </Box>

          </div>
        </Box>

      </div>


  );
}

export default Login;
