import React, { Component } from 'react'
import loIsNull from 'lodash/isNull'
import loIsArray from 'lodash/isArray'
import { makeStyles, } from '@material-ui/core/styles';
import Select from 'react-virtualized-select'

import 'react-select/dist/react-select.css'
import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'


const useStyles = makeStyles(theme => ({
  root: {
    width: 200,
    flex: 1
  },
  option: {
    fontSize: 12
  }
}))

function optionRenderer ({ focusedOption, focusOption, key, labelKey, option, selectValue, style, valueArray }) {
  const className = ['VirtualizedSelectOption']
  if (option === focusedOption) {
    className.push('VirtualizedSelectFocusedOption')
  }
  if (option.disabled) {
    className.push('VirtualizedSelectDisabledOption')
  }
  if (valueArray && valueArray.indexOf(option) >= 0) {
    className.push('VirtualizedSelectSelectedOption')
  }

  const events = option.disabled
    ? {}
    : {
      onClick: () => selectValue(option),
      onMouseEnter: () => focusOption(option)
    }

  return (
    <div
      className={className.join(' ')}
      key={key}
      style={{
        ...style,
        ...option.style,
        fontSize: 12,
        overflow: 'auto'
      }}
      title={option.title}
      {...events}
    >
      {option[labelKey]}
    </div>
  )
}

const VirtualizedSelect = (props) => {
  const {
    options,
    onChange,
    value,
    multi = true,
    searchable = true,
  } = props
  const classes = useStyles()

  const onChangeSelection = (val) => {
    if(loIsNull(val)) {
      //clearing the filter
      onChange([])
      return
    }
    const newSelection = loIsArray(val) ? val : [val]
    onChange(newSelection)
  }
  return (
    <Select
      menuIsOpen={true}
      isOpen={true}
      className={classes.selectRoot}
      options={options}
      onChange={onChangeSelection}//(selectValue) => this.setState({ selectValue })}
      value={value}//this.state.selectValue}
      multi={multi}
      searchable={searchable}
      removeSelected={false}
      closeOnSelect={false}
      clearable={true}
      optionClassName={classes.option}
      autosize={false}
      optionRenderer={optionRenderer}
      // optionHeight={({option}) => {
      //   console.log('calc option height option', option)
      //   return 100
      // }}
    />
  )
}
export default VirtualizedSelect
