import { getActions } from '../utils/actions';
import {apiCall} from '../utils/api'


// export const fetchSheetData = () => {
//   const actions = getActions('FETCH_SHEET_DATA');
//   return async (dispatch, getState) => {
//     dispatch(actions.requested());
//     try {
//         const res = await apiCall({
//           url: '/',
//           method: 'GET'
//         });
//         // fetch('http://localhost:3003/')
//         // .then(res => {
//         //     return res.json()
//         // })
//         // .then(jsonRes => {
//         //     console.log('json res', jsonRes)
//         //     return dispatch(actions.succeeded(jsonRes));
//         // } )
//         // .catch(e => {
//         //     console.log('caught e', e)
//         //     return dispatch(actions.failed({ error: e }));
//         // })
//         console.log('res', res)
//         return dispatch(actions.succeeded(res));
//
//     } catch (e) {
//         console.log('caught error e', e)
//         return dispatch(actions.failed({ error: e }));
//     }
//
//
//   };
// };

export const fetchSheetData = () => {
  const actions = getActions('FETCH_SHEET_DATA');
  return async (dispatch, getState) => {
    dispatch(actions.requested());
    try {
        const res = await apiCall('GET', {
          url: '/data/sheet',
        })
        return dispatch(actions.succeeded(res));

    } catch (e) {
        return dispatch(actions.failed({ error: e }));

    }


  };
};
