import React from 'react'
import { makeStyles, useTheme , fade} from '@material-ui/core/styles';
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import loIsArray from 'lodash/isArray'
import loIsNull from 'lodash/isNull'
import loIsEmpty from 'lodash/isEmpty'
import loGet from 'lodash/get'
import FilterListIcon from '@material-ui/icons/FilterList';
import {submitFilters} from '../../actions/filters'
import {
  Select,
  Box,
} from '@material-ui/core';
import Button from '../presentation/Button';
import Paper from '../presentation/Paper';
import Typography from '../presentation/Typography';
import COLUMN_NAMES, {YES_VALUE, NO_VALUE, EXCLUDED_COLUMNS_FROM_FILTERS} from '../../constants/columnNames'

const useStyles = makeStyles(theme => ({
  filterIcon: {
  },
  largeIcon: {
    fontSize: 46
  },
  container: {
    backgroundColor: fade(theme.palette.common.white, 0.45),
    padding: 12
  },
  shortcutBox: {
    border: '2px solid transparent',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }

  },
  selectedShortcut: {
    border: '2px solid white',
  }
}))


const colors = [
  '#ff2e06',
  '#eec500',
  '#ff7321',
  '#99d6c9'
]



const FilterShortcuts = (props) => {
  const classes = useStyles()
  const theme = useTheme()
    const dispatch = useDispatch()
  const {
    onSubmit,
    onReset,
    columns
  } = props
  const submittedFilters = useSelector(state => state.filters.submitted)
  const dropdownOptionsByColumn = useSelector(state => state.filters.dropdownOptions)

  const areFiltersApplied = React.useMemo(() => {
    return !loIsEmpty(submittedFilters)
  }, [submittedFilters])

  const shortcutColumns = React.useMemo(() => {

    return (columns || []).filter(columnName => {
      const options = dropdownOptionsByColumn[columnName]
      const shouldInclude =  options.length <= 2 && options.some(option => option.value === YES_VALUE || option.value === NO_VALUE)
      // if(shouldInclude) {
      //   console.log('cell options for shortcut column',columnName, options)
      //
      // }
      return !EXCLUDED_COLUMNS_FROM_FILTERS.includes(columnName) && shouldInclude
    })

  }, [dropdownOptionsByColumn, columns])


    const onSubmitFilters = (filters) => {
      // console.log('submitting shortcut filters', filters)
      dispatch(submitFilters(filters))
    }

    const removeFilter = (columnName) => () => {
      // console.log('removing filter for columnname', columnName)
      const newSelectedFilters = {...submittedFilters}
      delete newSelectedFilters[columnName]
      onSubmitFilters(newSelectedFilters)
    }

    const applyFilter = (columnName) =>  () =>{
      // console.log('applying shortcut fitler for columnanme', columnName)
      onSubmitFilters({
        ...submittedFilters,
        [columnName]: [{label: YES_VALUE, value: YES_VALUE}]
      })
    }

    return (
        <Box
          className={classes.container}
          width="100%"
          display="flex"
          alignItems="center"
          overflow="auto"
          flex="0 0 auto"
          my={1}
        >
          {shortcutColumns.map((columnName,i) => {
            const isSelected = loGet(submittedFilters, [columnName, 0, 'value']) === YES_VALUE

            return <Box
              className={classNames(classes.shortcutBox, {
                [classes.selectedShortcut]: isSelected
              })}
              flex="0 0 auto"
              borderRadius="5px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="150px"
              height="100px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              py={"4px"}
              px={"4px"}
              bgcolor={colors[(i + 1) % colors.length]}
              mr={2}
              onClick={isSelected ? removeFilter(columnName) : applyFilter(columnName)}
                   >
              <Typography fontSize={12} color="white" weight="bold" align="center" style={{wordBreak: 'break-word'}}>
                {columnName}

              </Typography>
            </Box>
          })}


        </Box>
    )
}

export default FilterShortcuts
